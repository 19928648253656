import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import "../scss/main.scss";
import "../scss/cgv.scss";

const CgvPage = () => {
	return (
		<Layout>
			<Seo title="Conditions Générales de Vente" />
			<div className="page-content page-statuts">
			  <section className="page-section cgv-section">
			    <h1>Conditions Générales de Vente</h1>
			    <h2 className="cgv-title" id="objet">Objet</h2>
			    <p className="cgv-paragraph">
			      Les présentes Conditions Générales de Vente constituent, conformément à l’article 
			      L.441-1 du Code du Commerce, le socle unique de la relation commerciale entre les
			      parties.
			    </p>
			    <p className="cgv-paragraph">
			      Elles ont pour objet de définir les conditions dans lesquelles la CLI fournit aux
			      clients professionnels qui lui en font la demande, via le site internet de la CLI,
			      par contact direct, courriel ou via un support papier, les services suivants :
			    </p>
			    <ul>
			      <li>Développement web</li>
			      <li>Développement logiciel</li>
			      <li>Maintenance</li>
			      <li>Auto-hébergement</li>
			      <li>Infrastructure système et Réseau</li>
			      <li>Audit de sécurité</li>
			    </ul>
			    <p className="cgv-paragraph">
			      Elles s’appliquent, sans restriction ni réserve, à tous les services rendus par la CLI auprès des Clients de même catégorie, quelles que soit les clauses pouvant figurer sur les documents du Client, et notamment ses conditions générales d’achat.
			    </p>
			    <p className="cgv-paragraph">
			      Conformément à la réglementation en vigueur, ces Conditions Générales de Vente sont systématiquement communiquées à tout Client qui en fait la demande, pour lui permettre de passer commande auprès de la CLI. Elles sont également communiquées à tout Client préalablement à la conclusion d’une convention unique visée aux articles L 441-3 et suivants du Code du Commerce, dans les délais légaux.
			    </p>
			    <p className="cgv-paragraph">
			      Toute commande de services implique, de la part du Client, l’acceptation des présentes Conditions Générales de Vente et des conditions générales d’utilisation du site internet de la CLI pour les commandes électroniques.
			    </p>
			    <p className="cgv-paragraph">
			      Les renseignements figurant sur les catalogues, prospectus et tarifs de la CLI sont donnés à titre indicatif et sont révisables à tout moment.
			    </p>
			    <p className="cgv-paragraph">La CLI est en droit d’y apporter toutes modifications qui lui paraîtront utiles.
			    </p>
			    <h2 className="cgv-title" className="cgv-title" id="définitions">Définitions</h2>
			    <h3 id="client">2.1 Client</h3>
			    <p className="cgv-paragraph">
			      Le client désigne toute personne physique ou morale effectuant une commande d’une des prestations de la CLI.</p>
			    <h3 id="les-personnes-concernées">2.2 Les personnes concernées</h3>
			    <p className="cgv-paragraph">Cette expression désigne les personnes dont les données personnelles son traitées, indépendamment de l’entité qui les traite (la CLI, le client, ou d’autres prestataires).</p>
<h3 id="les-partis">2.3 Les partis</h3>
<p className="cgv-paragraph">Les partis désignent à la fois la CLI et l’organisation passant commande ou demandant un devis.</p>
<h3 id="contrat">2.4 Contrat</h3>
<p className="cgv-paragraph">Le contrat définit la relation convenu entre la CLI et le client. Il est acté à partir du moment ou le devis est accepté, aux conditions de ce devis.</p>
<h3 id="prestation">2.5 Prestation</h3>
<p className="cgv-paragraph">La prestation désigne l’ensemble du travail fournit ou à fournir par la CLI, prévu par le devis accepté par le client.</p>
<h2 className="cgv-title" id="obligations-des-parties-communes-à-toutes-les-prestations">Obligations des parties communes à toutes les prestations</h2>
<h3 id="obligations-communes">3.1 Obligations communes</h3>
<p className="cgv-paragraph">Le Client reconnaît et accepte que la fourniture des prestations par la CLI dépend notamment de sa collaboration active et permanente.</p>
<p className="cgv-paragraph">A ce titre, chaque partie s’engage à informer l’autre, dès qu’elle en a connaissance, de toutes modifications de nature à modifier les conditions d’exécution des prestations.</p>
<h3 id="obligations-de-la-cli">3.2 Obligations de la CLI</h3>
<p className="cgv-paragraph">Les parties reconnaissent et acceptent qu’au titre de la réalisation des prestations et en raison de la technicité des technologies mises en œuvre, la CLI est tenue par une obligation générale de moyens.</p>
<p className="cgv-paragraph">La CLI s’engage à apporter tout le soin et la diligence nécessaires conformément aux usages de la profession, à l’état de la technique et à la nature de la prestation commandée.</p>
<h3 id="obligations-du-client">3.3 Obligations du client</h3>
<p className="cgv-paragraph">Le Client s’engage à transmettre avec précision, sous sa responsabilité, l’ensemble des informations nécessaires à l’exécution des prestations commandées. Le Client devra, notamment, exprimer clairement ses besoins et ses contraintes, afin d’établir précisément le périmètre des prestations.</p>
<p className="cgv-paragraph">En conséquence, si des pertes, dommages ou déficiences dans les prestations fournies par la CLI résultaient d’informations ou de documents inexacts, incomplets ou autrement défectueux fournis par le Client, la responsabilité de la CLI ne pourrait être retenue.</p>
<h2 className="cgv-title" id="commandes">Commandes</h2>
<h3 id="conditions-de-commande">4.1 Conditions de commande</h3>
<p className="cgv-paragraph">Les ventes de services ne sont parfaites qu’après établissement d’un devis et acceptation expresse et par écrit de la commande du Client par la CLI, matérialisée par un accusé de réception émanant de la CLI et acceptation du devis.</p>
<p className="cgv-paragraph">La CLI dispose de moyens de commande (y compris d’acceptation et de confirmation) électroniques (<a href="http://www.cli.coop/">www.cli.coop</a> ou par courriel) permettant aux Clients de commander les services dans les meilleures conditions de commodité et de rapidité.</p>
<p className="cgv-paragraph">Le Client a la possibilité de vérifier le détail de sa commande, le prix total et de corriger d’éventuelles erreurs avant de confirmer son acceptation (article 1127-2 du Code Civil). Cette validation implique l’acceptation de l’intégralité des présentes Conditions Générales de Vente et constituent une preuve du contrat de prestation de service.</p>
<p className="cgv-paragraph">La prise en compte de la commande et l’acceptation de celle-ci sont confirmées par l’envoi d’un courriel.</p>
<p className="cgv-paragraph">Les données enregistrées dans le système informatique de la CLI constituent la preuve de l’ensemble des transactions conclues avec le Client.</p>
<h3 id="modification-de-commande">4.2 Modification de commande</h3>
<p className="cgv-paragraph">Les éventuelles modifications de la commande demandées par le Client ne seront prises en compte, dans la limite des possibilités de la CLI, que si elles sont notifiées par écrit, sept (7) jours ouvrables au moins après que la commande ai été réalisé et après signature par le Client d’un bon de commande spécifique et ajustement éventuel du prix.</p>
<p className="cgv-paragraph">Si plusieurs contact ont été réalisé par le client pour cette prestation, la date de commande retenu sera celle du premier devis signé par le client pour cette prestation ou ensemble de prestation..</p>
<h3 id="annulation-de-commande">4.3 Annulation de commande</h3>
<p className="cgv-paragraph">En cas d’annulation de la commande par le Client après son acceptation par la CLI plus de sept (7) jours ouvrables après la date de commande, pour quelque raison que ce soit hormis la force majeure, l’acompte versé à la commande, tel que défini à l’article " Conditions de règlement-Délais de règlement " des présentes Conditions Générales de Vente sera de plein droit acquis à la CLI et ne pourra donner lieu à un quelconque remboursement.</p>
<p className="cgv-paragraph">Si plusieurs contact ont été réalisé par le client pour cette prestation, la date de commande retenu sera celle du premier devis signé par le client pour cette prestation ou ensemble de prestation.</p>
<h2 className="cgv-title" id="tarifs">Tarifs</h2>
<p className="cgv-paragraph">Les prestations de services sont fournies aux tarifs de la CLI en vigueur au jour de la passation de la commande, selon le barème de la CLI le devis préalablement établi par la CLI et accepté par le Client, comme indiqué à l’article " Commandes " ci-dessus.</p>
<p className="cgv-paragraph">Les tarifs s’entendent nets et HT.</p>
<p className="cgv-paragraph">Une facture est établie par la CLI et remise au Client lors de chaque fourniture de services.</p>
<p className="cgv-paragraph">Les conditions de détermination du coût des services dont le prix ne peut être connu a priori ni indiqué avec exactitude, ainsi que la méthode de calcul du prix permettant de vérifier ce dernier, seront communiquées au Client ou feront l’objet d’un devis détaillé, à la demande du Client conformément aux dispositions de l’article L 441-1,III du Code de commerce.</p>
<h2 className="cgv-title" id="conditions-de-règlement">Conditions de règlement</h2>
<h3 id="délais-de-règlement">6.1 Délais de règlement</h3>
<p className="cgv-paragraph">Un acompte correspondant à 15% du prix total des services commandés est exigé lors de la passation de la commande.</p>
<p className="cgv-paragraph">Le solde du prix est payable au comptant, au jour de la fourniture desdites prestations, dans les conditions définies à l’article «Modalités de fourniture des services» ci-après.</p>
<p className="cgv-paragraph">La CLI ne sera pas tenu de procéder à la fourniture des services commandés par le Client si celui-ci ne lui n’en paye pas le prix dans les conditions et selon les modalités indiquées aux présentes Conditions Générales de Vente.</p>
<p className="cgv-paragraph">Aucun escompte ne sera pratiqué par la CLI pour paiement avant la date figurant sur la facture ou dans un délai inférieur à celui mentionné aux présentes Conditions Générales de Vente.</p>
<h3 id="modalités-de-règlement">6.2 Modalités de règlement</h3>
<p className="cgv-paragraph">Le règlement des commandes se fait par virement bancaire. Le RIB de la CLI est fourni sur les devis et factures.</p>
<h3 id="pénalités-de-retard">6.3 Pénalités de retard</h3>
<p className="cgv-paragraph">En vertu de l’article L 441-10 du Code de commerce, sauf disposition contraire, le taux d’intérêt des pénalités de retard est égal au taux d’intérêt appliqué par la BCE à son opération de refinancement la plus récente majoré de 10 points de pourcentage. Cependant, ce taux ne peut être inférieur à trois fois le taux d’intérêt légal. Ainsi, le taux applicable pendant le premier semestre de l’année concernée est le taux en vigueur au 1er janvier de l’année en question. Pour le second semestre de l’année concernée, il est le taux en vigueur au 1er juillet de l’année en question. En cas de détermination du taux par les parties, ce dernier ne doit pas être abusif.</p>
<p className="cgv-paragraph">Les pénalités ayant pour assiette les sommes dues par le Client doivent être calculées sur la base du prix TTC figurant sur la facture et non sur le prix HT.</p>
<p className="cgv-paragraph">La formule de calcul des pénalités est : Pénalités de retard = [ (taux) x montant TTC] x [nombre de jours de retard / 365].</p>
<p className="cgv-paragraph">En cas de retard de paiement et de versement des sommes dues par le Client au-delà du délai ci-dessus fixé, et après la date de paiement figurant sur la facture adressée à celui-ci, des pénalités de retard calculées au taux de 20 % du montant TTC du prix des services figurant sur ladite facture tous les mois, seront automatiquement et de plein droit acquises à la CLI, sans formalité aucune ni mise en demeure préalable.</p>
<p className="cgv-paragraph">Si le retard de paiement entraîne l’exigibilité immédiate des sommes dues :</p>
<p className="cgv-paragraph">Le retard de paiement entraînera l’exigibilité immédiate de la totalité des sommes dues à la CLI par le Client, sans préjudice de toute autre action que la CLI serait en droit d’intenter, à ce titre, à l’encontre du Client.</p>
<p className="cgv-paragraph">En cas de non-respect des conditions de paiement figurant ci-dessus, la CLI se réserve en outre le droit de suspendre d’annuler la fourniture des services commandés par le Client, de suspendre l’exécution de ses obligations et de diminuer d’annuler les éventuelles remises accordées à ce dernier.</p>
<p className="cgv-paragraph">Enfin, une indemnité forfaitaire pour frais de recouvrement, d’un montant de 40 euros sera due, de plein droit et sans notification préalable par le client en cas de retard de paiement. la CLI se réserve le droit de demander au client une indemnisation complémentaire si les frais de recouvrement effectivement engagés dépassaient ce montant, sur présentation des justificatifs.</p>
<h3 id="absence-de-compensation">6.4 Absence de compensation</h3>
<p className="cgv-paragraph">Sauf accord exprès, préalable et écrit de la CLI, et à condition que les créances et dettes réciproques soient certaines, liquides et exigibles, aucune compensation ne pourra être valablement effectuée par le Client entre d’éventuelles pénalités pour retard dans la fourniture des services commandés ou non-conformité à la commande, d’une part, et les sommes par le Client à la CLI au titre de l’achat desdits services, d’autre part.</p>
<h2 className="cgv-title" id="modalités-de-fourniture-des-services">Modalités de fourniture des services</h2>
<p className="cgv-paragraph">Les services demandés par le Client seront fournis dans un délai maximum de 2 mois à compter de la réception par la CLI du bon de commande correspondant dûment signé, accompagné de l’acompte exigible.</p>
<p className="cgv-paragraph">Ce délai ne constitue pas un délai de rigueur et la CLI ne pourra voir sa responsabilité engagée à l’égard du Client en cas de retard dans la fourniture des services n’excédant pas 1 mois. En cas de retard supérieur à 1 mois, le Client pourra demander la résolution de la vente. Les acomptes déjà versés lui seront alors restitués par la CLI.</p>
<p className="cgv-paragraph">La responsabilité de la CLI ne pourra en aucun cas être engagée en cas de retard ou de suspension de la fourniture de la prestation imputable au Client, ou en cas de force majeure.</p>
<h2 className="cgv-title" id="confidentialité">Confidentialité</h2>
<p className="cgv-paragraph">Chacune des parties s’engage à garder secrètes et à considérer comme strictement confidentielles toutes les informations, communiquées et identifiées comme confidentielles, qui lui seront communiquées lors de l’exécution des prestations (également désignées « Informations Confidentielles »). Les parties s’engagent à prendre toutes mesures pour assurer la stricte confidentialité de ces informations confidentielles et conviennent que toute information confidentielle reçue de l’autre partie ne sera utilisée que pour fournir ou recevoir les prestations entre elles.</p>
<p className="cgv-paragraph">D’une manière générale, les parties s’engagent à prendre toute mesure nécessaire pour assurer, sous leur responsabilité, le respect de leurs obligations de confidentialité par l’ensemble de leurs salariés permanents et non permanents ainsi que par toute autre personne pouvant avoir accès aux informations échangées entre elles.</p>
<p className="cgv-paragraph">Nonobstant ce qui précède, les parties conviennent que ne sont pas considérées comme informations confidentielles :</p>
<ul>
<li><p className="cgv-paragraph">les informations généralement publiées ou accessibles au public, ainsi que celles qui le deviennent, autrement qu’à la suite d’une violation des présentes conditions;</p></li>
<li><p className="cgv-paragraph">les informations obtenues de tiers non soumis à une obligation de confidentialité concernant ces informations ;</p></li>
</ul>
<p className="cgv-paragraph">Cette obligation de confidentialité ne s’applique pas pour le cas où l’une des parties aurait besoin de justifier auprès de l’administration fiscale des écritures passées lors de l’exécution des prestations entre les parties, ou de faire valoir ses droits en justice au titre de l’exécution de ces mêmes prestations. Cette obligation de confidentialité ne s’applique pas d’avantage aux experts comptables et aux commissaires aux comptes des parties, ceux-ci étant soumis au secret professionnel de leurs clients, ni à l’administration fiscale en cas de contrôle de la comptabilité informatisée du Client par celle-ci.</p>
<p className="cgv-paragraph">Le présent engagement de confidentialité produira effet pendant toute la durée de la prestation et pendant cinq (5) ans à compter de son terme, pour quelle que cause que ce soit.</p>
<h2 className="cgv-title" id="protection-des-données-personnelles">Protection des données personnelles</h2>
<p className="cgv-paragraph">Dans le cas où le Client confie à la CLI des opérations de Traitement de Données personnelles, les parties s’engagent à respecter les dispositions du présent article.</p>
<p className="cgv-paragraph">Le présente article a pour objet de définir les conditions dans lesquelles le sous-traitant s’engage à effectuer pour le compte du responsable de traitement les opérations de traitement de données à caractère personnel définies ci-après.</p>
<p className="cgv-paragraph">Les termes définis ci-après auront la signification suivante :</p>
<ul>
<li>« Donnée personnelle » désigne toute information ou donnée telle que définie par le Droit applicable à la protection des Données, concernant une personne physique identifiée ou identifiable (ci-après «Personne concernée»), notamment par référence à un identifiant tel qu’un nom, un numéro d’identification, des données de localisation, un identifiant en ligne ou par référence à par un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.</li>
</ul>
<p className="cgv-paragraph">Le type de Données personnelles traité par le Sous-Traitant ainsi que les catégories de Personnes concernées doivent être précisés par le Client dans l’Annexe à la proposition commerciale intitulée « Traitement de données personnelles ».</p>
<ul>
<li><p className="cgv-paragraph">« Droit applicable à la protection des Données » désigne la loi de protection des Données personnelles de l’État membre de l’Union européenne où est établi le Responsable du Traitement, à savoir, à compter du 25 mai 2018, le RGPD ainsi que toutes législations et réglementations de l’État membre de l’Union européenne applicable(s) au Traitement.</p></li>
<li><p className="cgv-paragraph">« Pays Tiers » désigne tout pays n’appartenant pas à l’Espace Économique Européen (EEE) et ne disposant pas d’une législation en matière de traitement de données personnelles jugée adéquate par décision de la Commission européenne.</p></li>
<li><p className="cgv-paragraph">« Responsable de traitement» désigne toute personne physique ou morale qui détermine les finalités et moyens du Traitement devant être effectué au titre de l’exécution du Contrat. Dans le cadre des présentes, les Parties conviennent que le Responsable de Traitement est le Client.</p></li>
<li><p className="cgv-paragraph">« RGPD » désigne le Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données personnelles et à la libre circulation de ces données, et abrogeant la Directive 95/46/CE.</p></li>
<li><p className="cgv-paragraph">« Sous-traitant » désigne aux termes du présent Article,toute personne physique ou morale qui réalise un Traitement pour le compte du Responsable de Traitement. Les Parties conviennent que dans le cadre des présentes le Sous-traitant est la CLI. </p></li>
<li><p className="cgv-paragraph">« Traitement » désigne toute opération ou ensemble d’opérations effectuées ou non à l’aide de procédés automatisés portant sur des Données personnelles, tels que notamment la collecte, l’enregistrement, l’organisation, la conservation, l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l’interconnexion, ainsi que la limitation, l’effacement ou la destruction. »</p></li>
</ul>
<h3 id="description-du-traitement-faisant-lobjet-de-la-sous-traitance">9.1 Description du Traitement faisant l’objet de la sous-traitance</h3>
<p className="cgv-paragraph">Tout Traitement effectué pour le compte du Client est décrit dans l’Annexe intitulée « Traitement de données personnelles » fournie avec le devis. Elle définira entre autre le cadre du traitement des données.</p>
<h3 id="obligations-du-sous-traitant-vis-à-vis-du-responsable-de-traitement">9.2 Obligations du Sous-traitant vis-à-vis du Responsable de traitement</h3>
<p className="cgv-paragraph">La CLI s’engage à :</p>
<ul>
<li><p className="cgv-paragraph">traiter les données uniquement pour la ou les seule(s) finalité(s) qui fait/font l’objet de la prestation de service ;</p></li>
<li><p className="cgv-paragraph">traiter les données conformément aux instructions documentées du responsable de traitement. Si la CLI considère qu’une instruction constitue une violation du RGPD ou de toute autre disposition du droit de l’Union ou du droit des États membres relative à la protection des données, il en informe sans délai le Client. En outre, si la CLI est tenu de procéder à un transfert de données vers un Pays Tiers ou à une organisation internationale, en vertu du droit de l’Union ou du droit de l’État membre auquel il est soumis, il doit informer le Client de cette obligation juridique avant le traitement, sauf si le droit concerné interdit une telle information pour des motifs importants d’intérêt public ;</p></li>
<li><p className="cgv-paragraph">garantir la confidentialité des Données personnelles traitées dans le cadre du présent contrat ;</p></li>
<li><p className="cgv-paragraph">veiller à ce que les personnes autorisées à traiter les données personnelles dans le cadre de sa de la prestation de service :</p></li>
<li><p className="cgv-paragraph">s’engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité ;</p></li>
<li><p className="cgv-paragraph">reçoivent la formation nécessaire en matière de protection des données personnelles.</p></li>
<li><p className="cgv-paragraph">prendre en compte, s’agissant de ses outils, produits, applications ou services, les principes de protection des données dès la conception et de protection des données par défaut ;</p></li>
</ul>
<p className="cgv-paragraph">La CLI peut faire appel à un autre sous-traitant (ci-après le « Sous-traitant Ultérieur ») pour mener des activités de Traitement spécifiques. Dans ce cas, il informe préalablement et par écrit le Client de tout changement envisagé concernant l’ajout ou le remplacement d’autres sous-traitants. Cette information doit indiquer clairement les activités de Traitement sous-traitées, l’identité et les coordonnées du Sous-traitant Ultérieur et les dates du contrat de sous-traitance.</p>
<p className="cgv-paragraph">Le Client dispose d’un délai de sept (7) jours ouvrables à compter de la date de réception de cette information pour présenter ses objections. Cette sous-traitance ne peut être effectuée que si le Client n’a pas émis d’objection pendant le délai convenu.</p>
<p className="cgv-paragraph">Le Sous-traitant Ultérieur est tenu de respecter les obligations décrites dans ces mêmes CGV pour le compte du Client. Il appartient à la CLI de s’assurer que le Sous-traitant Ultérieur présente les mêmes garanties suffisantes quant à la mise en œuvre de mesures techniques et organisationnelles appropriées de manière à ce que le Traitement réponde aux exigences du Règlement européen sur la protection des données. Si le Sous-traitant Ultérieur ne remplit pas ses obligations en matière de protection des données, la CLI demeure pleinement responsable devant le Client de l’exécution par le Sous-traitant Ultérieur de ses obligations.</p>
<h3 id="droit-dinformation-des-personnes-concernées">9.2.1 Droit d’information des personnes concernées</h3>
<p className="cgv-paragraph">Il appartient au responsable de traitement des données personnelles du client d’informer les personnes concernées par les opérations de traitement au moment de la collecte des données.</p>
<h3 id="exercice-des-droits-des-personnes">9.2.2 Exercice des droits des personnes</h3>
<p className="status-paragraph">Dans la mesure du possible, la CLI doit aider le responsable de traitement à s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des personnes concernées : droit d’accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage).</p>
<p className="status-paragraph">Lorsque les personnes concernées exercent auprès de la CLI des demandes d’exercice de leurs droits, la CLI doit adresser ces demandes dès réception par courrier électronique au responsable du traitement du client.</p>
<p className="status-paragraph">Conformément aux lois en vigueur, la CLI se réserve le droit de transmettre toutes informations les concernant aux personnes faisant l’objet d’un traitement des données.</p>
<h3 id="notification-des-violations-de-données-à-caractère-personnel">9.2.3 Notification des violations de données à caractère personnel</h3>
<p className="status-paragraph">La CLI notifie au Client toute violation de données personnelles dans un délai maximum de 48 heures ouvrées après en avoir pris connaissance et par courrier électronique.</p>
<p className="status-paragraph">Cette notification est accompagnée de toute documentation utile afin de permettre au Client, si nécessaire, de notifier cette violation à l’autorité de contrôle compétente.</p>
<h3 id="aide-du-sous-traitant-dans-le-cadre-du-respect-par-le-responsable-de-traitement-de-ses-obligations">9.2.4 Aide du Sous-traitant dans le cadre du respect par le Responsable de traitement de ses obligations</h3>
<p className="status-paragraph">Dans la mesure où son aide est légalement requise et sur demande du Client, la CLI aide le Client et fournit les informations nécessaires dont il est le seul à disposer pour la réalisation d’analyses d’impact relative à la protection des Données.</p>
<p className="status-paragraph">La CLI aide le Client et fournit les informations nécessaires dont il dispose pour la réalisation de la consultation préalable de l’autorité de contrôle. Cette obligation ne saurait entrer en contradiction avec le respect des données personnelles.</p>
<p className="status-paragraph">Il est précisé que toute assistance au-delà de celle légalement requise sera soumise à un devis devant être accepté par le Client.</p>
<h3 id="sort-des-données">9.2.5 Sort des données</h3>
<p className="status-paragraph">Au terme de la prestation de services relatifs au traitement de ces Données et selon l’accord des Parties, la CLI s’engage à :</p>
<ul>
<li><p className="status-paragraph">détruire toutes les Données personnelles ou ;</p></li>
<li><p className="status-paragraph">à renvoyer toutes les Données personnelles au Responsable de traitement ou</p></li>
<li><p className="status-paragraph">à renvoyer les données à caractère personnel au sous-traitant désigné par le responsable de traitement.</p></li>
</ul>
<p className="status-paragraph">Le renvoi doit s’accompagner de la destruction de toutes les copies existantes dans les systèmes d’information du sous-traitant. Le Responsable de traitement pourra demander que le Sous-traitant justifie par écrit de la destruction.</p>
<p className="status-paragraph">Dans le cas d’un accord explicite de la personne désignée par ces données personnelles, la CLI pourra conserver ces données pour un usage et une durée déterminé et explicite. Dans ce cas, il sera fournit à cette personne un moyen de contact pour faire valoir ses droits (de modifications ou de suppression par exemple)</p>
<h3 id="délégué-à-la-protection-de-données">9.2.6 Délégué à la protection de données</h3>
<p className="status-paragraph">Le sous-traitant communique au responsable de traitement le nom et les coordonnées de son délégué à la protection des données, s’il en a désigné un conformément à l’article 37 du règlement européen sur la protection des données.</p>
<h3 id="registre-des-catégories-dactivités-de-traitement">9.2.7 Registre des catégories d’activités de traitement</h3>
<p className="status-paragraph">Le sous-traitant déclare tenir par écrit un registre par le biais de son système de gestion de ticket de toutes les catégories d’activités de traitement effectuées pour le compte du responsable de traitement comprenant :</p>
<ul>
<li><p className="status-paragraph">le nom et les coordonnées du responsable de traitement pour le compte duquel il agit, des éventuels sous-traitants et, le cas échéant, du délégué à la protection des données ;</p></li>
<li><p className="status-paragraph">les catégories de traitements effectués pour le compte du responsable du traitement ;</p></li>
<li><p className="status-paragraph">le cas échéant, les transferts de données à caractère personnel vers un pays tiers ou à une organisation internationale, y compris l’identification de ce pays tiers ou de cette organisation internationale et, dans le cas des transferts visés à l’article 49, paragraphe 1, deuxième alinéa du règlement européen sur la protection des données, les documents attestant de l’existence de garanties appropriées. Une description générale des mesures de sécurité techniques et organisationnelles.</p></li>
</ul>
<h3 id="obligations-du-responsable-de-traitement-vis-à-vis-du-sous-traitant">9.3 Obligations du Responsable de traitement vis-à-vis du Sous-traitant</h3>
<p className="status-paragraph">Le Client s’engage à :</p>
<ul>
<li><p className="status-paragraph">fournir au sous-traitant les données visées à l’article des présentes clauses</p></li>
<li><p className="status-paragraph">documenter par écrit toute instruction concernant le traitement des données par le sous-traitant</p></li>
<li><p className="status-paragraph">veiller, au préalable et pendant toute la durée du Traitement, au respect des obligations prévues par le RGPD de la part du Sous-traitant ;</p></li>
<li><p className="status-paragraph">superviser le Traitement, y compris réaliser les audits et les inspections auprès du sous-traitant selon les conditions relatives aux audits énoncées dans les présentes.</p></li>
</ul>
<h2 className="cgv-title" id="suspension-des-prestations">Suspension des prestations</h2>
<p className="status-paragraph">La CLI se réserve la possibilité de suspendre, moyennant un écrit (y compris par mail) et le respect d’un préavis de 48 heures, la fourniture totale ou partielle des prestations du Contrat si le Client manque au respect de l’une de ses obligations contractuelles et/ou légales c’est-à-dire en cas de violation notamment des dispositions du Contrat ou des lois et règlements applicables.</p>
<p className="status-paragraph">Cette suspension des prestations pourra être décidée par la CLI sans aucun préavis si :</p>
<ul>
<li><p className="status-paragraph">la CLI a des raisons légitimes de penser que le Client utilise frauduleusement les services souscrits, qu’il tente de le faire, ou qu’il agit en violation des lois et règlements applicables dans le cadre de son utilisation des services;</p></li>
<li><p className="status-paragraph">le Client empêche ou perturbe l’exécution par la CLI de ses propres obligations contractuelles et/ou légales.</p></li>
</ul>
<p className="status-paragraph">La CLI pourra ensuite rétablir l’exécution des prestations ou choisir de résilier le Contrat dans les conditions de l’article suivant, en notifiant par écrit au Client sa décision.</p>
<h2 className="cgv-title" id="résiliation">Résiliation</h2>
<p className="status-paragraph">En cas de manquement grave par l’une des parties aux obligations des présentes conditions, non réparé dans un délai de trente (30) jours à compter de la réception de la mise en demeure adressée en lettre recommandée avec accusé de réception à cet effet, l’autre partie pourra prononcer, la résiliation du Contrat par lettre recommandée avec accusé de réception.</p>
<p className="status-paragraph">En cas de manquement d’une gravité telle qu’elle rende impossible la poursuite de la relation contractuelle (défaut de loyauté, comportement contraire à l’éthique, aux lois d’ordre public…),la résiliation sera effective de plein droit sur simple notification, sans préavis, et ne donnera lieu à aucune indemnité, sans préjudice de tout recours ou action judiciaire qui pourrait être initié par la partie subissant un préjudice.</p>
<p className="status-paragraph">En tout état de cause, le Client reste tenu de payer à la CLI le prix de l’ensemble des prestations réalisées jusqu’à la date effective de la résiliation.</p>
<p className="status-paragraph">Chaque partie devra restituer à l’autre partie tous les biens appartenant à cette dernière en sa possession ou sous son contrôle</p>
<p className="status-paragraph">Sauf disposition contraire explicité ailleurs dans le contrat de prestation de services, une telle résiliation rendrait de fait la CLI propriétaire de toutes productions intellectuelles réalisée durant ce contrat.</p>
<h2 className="cgv-title" id="responsabilité-des-partis">Responsabilité des partis</h2>
<p className="status-paragraph">Chaque partie (CLI et Client) supportera la charge des dommages qui lui sont directement imputables et susceptibles d’être causés à l’autre partie et/ou aux tiers.</p>
<p className="status-paragraph">Il est expressément convenu entre les parties que la présente clause survivra au terme du contrat de prestation quelle qu’en soit la cause.</p>
<h3 id="responsabilité-de-la-cli">12.1 Responsabilité de la CLI</h3>
<p className="status-paragraph">La CLI s’engage à exécuter ses prestations avec tout le professionnalisme requis.</p>
<p className="status-paragraph">Au cas où la responsabilité de la CLI serait engagée au titre de la prestation, sur quelque fondement que ce soit, il est convenu que l’indemnisation cumulée qui pourrait être réclamée à la CLI par année contractuelle pour les dommages directs subis par le Client sera limitée au montant total annuel HT des prestations commandées et payées au titre du devis concerné.</p>
<p className="status-paragraph">Toutefois, la limite d’indemnisation stipulée au présent article ne s’applique pas aux dommages subis par le Client du fait :</p>
<ul>
<li><p className="status-paragraph">d’une faute lourde et/ou dolosive, et/ou intentionnelle de la CLI ou de l’un de ses employés, sous-traitants, mandataires éventuels ;</p></li>
<li><p className="status-paragraph">de dommages corporels ou de décès causés par la CLI.</p></li>
</ul>
<p className="status-paragraph">En aucun cas la responsabilité de la CLI ne saurait couvrir les dommages indirects.</p>
<p className="status-paragraph">La CLI ne saurait être tenue responsable des pannes, coupures de lignes, mauvaises configurations de matériel ou d’équipements ne se trouvant pas sous son contrôle direct, et notamment les liaisons de tout type assurés par les fournisseurs d’accès internet.</p>
<p className="status-paragraph">La CLI ne saurait être tenue responsable des éventuels contaminations et dommages par virus des données et/ou logiciels du Client dont la responsabilité incombe à ce dernier.</p>
<p className="status-paragraph">La CLI ne garantit nullement les taux de transfert et les temps de réponse des informations circulant sur le réseau Internet.</p>
<p className="status-paragraph">Enfin, le Client reconnaît et accepte formellement que la responsabilité de la CLI ne saurait être, directement ou indirectement retenue, à quelque titre que ce soit, pour les dommages liés à tout préjudice commercial, perte de bénéfice ou de client; par exemple, divulgation inopportune d’informations confidentielles les concernant par suite de défectuosité ou de piratage du système, perte de commande, trouble commercial quelconque, atteinte à l’image de marque ou action dirigée contre le Client par qui que ce soit et pour lesquels le Client sera son propre assureur ou pourra contracter les assurances appropriées.</p>
<p className="status-paragraph">La responsabilité de la CLI exclue:</p>
<ul>
<li><p className="status-paragraph">le fonctionnement du système si d’autres applications logicielles ont été installées sur le matériel informatique ou le serveur dédié sans consultation et accord préalable écrit de la CLI ;</p></li>
<li><p className="status-paragraph">les pertes d’informations ou détérioration de fichiers ou programmes causés par une mauvaise manipulation ou une négligence du personnel utilisateur pouvant entraîner entre autre un dysfonctionnement partiel ou total ;</p></li>
<li><p className="status-paragraph">non respect des normes d’installation matériel ou logiciel ;</p></li>
<li><p className="status-paragraph">modification du système d’exploitation initial entraînant un dysfonctionnement partiel ou total ( ex: destruction ou modification des fichiers de configuration ) ;</p></li>
<li><p className="status-paragraph">les mauvaises connexions de matériels périphériques entraînant un dysfonctionnement partiel ou total ;</p></li>
<li><p className="status-paragraph">les problèmes extérieurs entraînant un dysfonctionnement partiel ou total ( EDF, PTT ) ;</p></li>
<li><p className="status-paragraph">bugs ou virus logiciels qu’ils entraînent ou non un dysfonctionnement partiel ou total ;</p></li>
<li><p className="status-paragraph">l’utilisation de consommables non conformes aux spécifications des constructeurs ;</p></li>
<li><p className="status-paragraph">restauration ou récupération de fichiers utilisateurs non sauvegardés.</p></li>
</ul>
<p className="status-paragraph">La CLI facturera les déplacements et la main d’œuvre pour tous les cas ci-dessus.</p>
<h3 id="responsabilité-du-client">12.2 Responsabilité du Client</h3>
<p className="status-paragraph">Le Client est responsable, en tant que gardien, de la sécurité des matériels ainsi que des logiciels et dossiers présents sur l’ensemble de ses sites concernés par les prestations. Le Client s’oblige à prendre toutes mesures pour éviter qu’un dommage quelconque ne résulte pour lui d’une éventuelle atteinte aux fichiers, mémoires, documents ou tous autres éléments qu’il aurait pu confier dans le cadre des prestations. Le Client se prémunira, à cet effet contre les risques, en constituant un double des documents, fichiers et supports conformément à l’état de l’art en vigueur. A ce titre, le Client répondra desdits dommages dans la mesure où il en est directement et exclusivement responsable.</p>
<p className="status-paragraph">Il est précisé que la responsabilité du Client ne saurait en aucun cas couvrir les dommages indirects.</p>
<p className="status-paragraph">Le Client est seul responsable du contenu des informations transmises, diffusées ou collectées sur son application et de leur exploitation et mise à jour, notamment par transfert de fichiers.</p>
<h2 className="cgv-title" id="assurances">Assurances</h2>
<p className="status-paragraph">Chacune des parties atteste avoir souscrit une police d’assurance auprès d’une compagnie notoirement solvable, pour toutes les conséquences pécuniaires de sa responsabilité civile, professionnelle, délictuelle et/ou contractuelle du fait de dommages corporels, matériels et immatériels causés à l’autre partie et à tout tiers, de son seul fait dans le cadre de l’exécution de la prestation.</p>
<p className="status-paragraph">À ce titre, chacune des parties s’engage à acquitter les primes de cette police d’assurance et à en attester à la première demande de l’autre partie.</p>
<h2 className="cgv-title" id="cas-de-force-majeure-et-assimilés">Cas de force majeure et assimilés</h2>
<p className="status-paragraph">La CLI ne sera pas responsable de l’inexécution partielle ou totale de ses obligations ou de tout retard dans l’exécution de celles-ci, si cette inexécution ou ce retard ont été provoqués par la survenance d’événements imprévisibles, irrésistibles et extérieurs. Au sens des présentes Conditions Générales de Vente, sont assimilés à la force majeure :</p>
<ul>
<li><p className="status-paragraph">les pannes occasionnant des interruptions de service sur la connexion internet de la CLI, résultant de coupures d’alimentation électrique du fait du distributeur ;</p></li>
<li><p className="status-paragraph">le séisme, l’incendie, les inondations, la tempête, la foudre ou autres aléas climatiques ou encore les catastrophes naturelles et, plus généralement, toute circonstance, fait, accident qui entraîne la détérioration ou la destruction partielle ou totale des installations de la plateforme technique de la CLI pour des raisons indépendantes de la volonté de ce dernier :</p></li>
<li><p className="status-paragraph">les perturbations, dysfonctionnements ou interruption momentanée de l’alimentation électrique de la CLI par le réseau de distribution ;</p></li>
<li><p className="status-paragraph">l’indisponibilité des services découlant d’une interruption totale ou partielle du service des lignes administrées par les opérateurs télécom et fournisseurs d’accès auxquels la CLI a recours.</p></li>
<li><p className="status-paragraph">L’indisponibilité ou le retard dû a une immobilisation par la force publique.</p></li>
</ul>
<p className="status-paragraph">La CLI s’engage pour les deux derniers cas à entreprendre tout ce qui est en son pouvoir pour obtenir des exploitants responsables des perturbations, dysfonctionnements ou interruption le rétablissement des services leur incombant.</p>
<h2 className="cgv-title" id="acceptation-du-client">Acceptation du Client</h2>
<p className="status-paragraph">Les présentes Conditions Générales de Vente sont expressément agréées et acceptées par le Client, qui déclare et reconnaît en avoir une parfaite connaissance, et renonce, de ce fait, à se prévaloir de tout document contradictoire et, notamment, ses propres conditions générales d’achat, qui seront inopposables à la CLI, même si elle en a eu connaissance.</p>
<h2 className="cgv-title" id="maintenance">Maintenance</h2>
<p className="status-paragraph">Les logiciels développés par la CLI, les prestations d’auto-hébergement des données, et la liste de logiciels validée par la CLI peuvent faire l’objet d’un contrat de maintenance. La durée, les délais d’interventions et de résolutions, l’environnement logiciel, les modalités de renouvellement et les travaux réalisé dans le cadre de cette prestation seront définit dans le devis.</p>
<h2 className="cgv-title" id="loi-applicable-litiges">Loi applicable litiges</h2>
<h3 id="médiation">17.1 Médiation</h3>
<p className="status-paragraph">En vue de trouver ensemble une solution à tout litige qui surviendrait dans l’exécution du présent contrat, les parties conviennent de se réunir dans les dix (10) jours à compter de l’envoi d’une lettre recommandée avec accusé de réception, notifiée par l’une des parties. Si au terme d’un délai de dix (10) jours, les parties n’arrivaient pas à se mettre d’accord sur un compromis ou une solution, le litige serait alors transféré à la juridiction compétente ; sauf obligation légale contraire, il s’agira du Tribunal de Grande Instance de Strasbourg.</p>
<h3 id="litiges">17.2 Litiges</h3>
<p className="status-paragraph">Tous les litiges auxquels les présentes Conditions Générales de Ventes et les accords qui en découlent pourraient donner lieu, concernant tant leur validité , leur interprétation, leur exécution, leurs conséquences et leurs suites seront soumis au Tribunal de Grande Instance de Strasbourg.</p>
<h3 id="droit-applicable">17.3 Droit applicable</h3>
<p className="status-paragraph">Les présentes Conditions générales de Vente et les opérations qui en découlent sont régies par le droit français.</p>
<p className="status-paragraph">Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.</p>
<h2 className="cgv-title" id="renonciation">Renonciation</h2>
<p className="status-paragraph">Le fait pour la CLI de ne pas se prévaloir à un moment donné d’une quelconque des clauses des présentes Conditions Générales de Vente ne peut valoir renonciation à se prévaloir ultérieurement de ces mêmes clauses.</p>
<h2 className="cgv-title" id="droit-de-propriété-intellectuelle">Droit de propriété intellectuelle</h2>
<p className="status-paragraph">En l’absence de mention contraire dans le devis, toute production intellectuelle de la CLI, logicielle ou non, sont considérées comme sous licence CeCILL-2.1</p>
<p className="status-paragraph">Cette licence oblige la citation des personnes autrices (ici, la CLI). La CLI se réserve le droit de poursuite toute entorse à cette licence, dans les conditions prévues par la propriété intellectuelle en vigueur.
</p>
<p className="status-paragraph">Si pour des raison géographiques, temporelle, ou autre, la CLI n’était pas en mesure de faire valoir sa propriété intellectuelle, celle-ci doit-être considérée comme étant celle des personnes physiques ayant travaillé en son sein.
</p>
			  </section>
			</div>
		</Layout>
	);
};
export default CgvPage;
